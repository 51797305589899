import { global } from './global'

let _baseurl = global.baseApiUrl + 'esquema/'

export default {
  getEsquemasList() {
    return global.axios.get(_baseurl + 'get-esquema-list')
  },
  getAnunciosList() {
    return global.axios.get(_baseurl + 'get-anuncio-list')
  },
  saveAnuncio(data) {
    return global.axios.post(_baseurl + 'save-anuncio', data)
  },
  saveEsquema(data) {
    return global.axios.post(_baseurl + 'save-esquema', data)
  },
  deleteDocument(id) {
    return global.axios.post(_baseurl + 'delete-document?id=' + id)
  },
  getCalculadoraComisionEmpleado(param) {
    return global.axios.post(_baseurl + 'get-calculadora-comision-empleado', param)
  },
  getReporteTarifario(param) {
    return global.axios.post(_baseurl + 'get-reporte-tarifario', param)
  },

  getReporteTarifarioExport(param) {
    return global.axios.post(_baseurl + 'get-reporte-tarifario-export', param,{ responseType: 'blob', headers: { 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/json' } })
  },

}
