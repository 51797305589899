<template>
  <div>
    <v-card-text>
      <v-card outlined class="white pa-2">
        <v-tabs>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab href="#tab1">Reglas Comisiones</v-tab>
          <v-tab href="#tab2">Carousel</v-tab>
          <v-tab-item value="tab1">
            <div class="pa-1" style="background: white">
              <div class="text-right">
                <v-btn text color="primary" @click.native="reglas = true">
                  <v-icon left>add</v-icon>Nuevo registro
                </v-btn>
              </div>

              <v-data-table
                :headers="headersReglas"
                :items="itemsReglas"
                :items-per-page="5"
              >
                <template v-slot:item.CanalID="{ item }">{{
                  getCanalName(item.CanalID)
                }}</template>

                <template v-slot:item.bActivo="{ item }">
                  <v-chip label v-if="item.bActivo" color="info">Activo</v-chip>
                  <v-chip label v-if="!item.bActivo">Inactivo</v-chip>
                </template>

                <template v-slot:item.action="{ item }">
                   <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn text icon>
                        <v-icon @click="previewRegla(item)" v-on="on"
                          >fas fa-search</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Vista Previa</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        text
                        icon
                        @click="editItem(item, 'reglas')"
                        v-on="on"
                      >
                        <v-icon>far fa-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        text
                        icon
                        @click="deleteItem(item, 'reglas')"
                        v-on="on"
                      >
                        <v-icon>fas fa-trash-alt</v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </div>
          </v-tab-item>
          <v-tab-item value="tab2">
            <div class="pa-1" style="background: white">
              <div class="text-right">
                <v-btn text color="primary" @click.native="dialog = true">
                  <v-icon left>add</v-icon>Nuevo registro
                </v-btn>
              </div>
              <v-data-table :headers="headersCarousel" :items="itemsCarousel">
                <template v-slot:item.CanalID="{ item }">{{
                  getCanalName(item.CanalID)
                }}</template>

                <template v-slot:item.StartDate="{ item }">{{
                  item.StartDate | date
                }}</template>

                <template v-slot:item.EndDate="{ item }">{{
                  item.EndDate | date
                }}</template>

                <template v-slot:item.bActivo="{ item }">
                  <v-chip label v-if="item.bActivo" dark color="info"
                    >Activo</v-chip
                  >
                  <v-chip label v-if="!item.bActivo" dark>Inactivo</v-chip>
                </template>

                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn text icon>
                        <v-icon @click="previewAnuncio(item)" v-on="on"
                          >fas fa-search</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Vista Previa</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn text icon>
                        <v-icon @click="editItem(item, 'carousel')" v-on="on"
                          >far fa-edit</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn text icon>
                        <v-icon @click="deleteItem(item, 'carousel')" v-on="on"
                          >fas fa-trash-alt</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-card-text>

    <v-dialog v-model="reglas" width="400">
      <v-card>
        <v-form ref="formReglas">
          <v-card-title>
            <span>{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <!-- <v-row wrap>
              <v-col cols="12"> -->
            <v-text-field
              v-model="editedItem.Nombre"
              label="Nombre"
              :rules="notNull"
              required
            ></v-text-field>
            <!-- </v-col>
              <v-col cols="12"> -->
            <v-textarea
              v-model="editedItem.Descripcion"
              label="Descripcion"
              rows="2"
              :rules="notNull"
              required
              outline
              style="padding: 0px"
            ></v-textarea>
            <!-- </v-col>
              <v-col cols="12"> -->
            <v-select
              clearable
              v-model="editedItem.CanalID"
              :items="canales"
              label="Canal"
              item-value="CanalId"
              item-text="Nombre"
              append-icon="keyboard_arrow_down"
              style="padding: 0px"
            ></v-select>
            <!-- </v-col>
              <v-col cols="12"> -->

            <v-autocomplete
              :items="listGruposComisiones"
              :filter="customFilter"
              v-model="gruposcomisionesSelected"
              label="Grupos Comisiones"
              hint="Dejar vacio para usar todos"
              append-icon="keyboard_arrow_down"
              multiple
              chips
              deletable-chips
              persistent-hint
              clearable
              style="padding: 0px"
            ></v-autocomplete>
            <v-switch
              color="primary"
              :label="`Estado: ${editedItem.bActivo ? 'Activo' : 'Inactivo'}`"
              v-model="editedItem.bActivo"
            ></v-switch>
            <!-- </v-col>
              <v-col cols="12"> -->
            <v-file-input
              required="editedIndex < 0"
              :rules="editedIndex < 0?fileRules: undefined"
              label="Seleccionar archivo"
              v-model="file"
              accept="image/*"
              :show-size="1000"
              style="padding: 0px"
            ></v-file-input>
             <span style="color:gray;font-size:12px;padding: 0px">La imagen se adapta al dispositivo.</span>
            <!-- </v-col>
            </v-row> -->
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" depressed @click.native="save('reglas')"
              >Guardar</v-btn
            >
            <v-btn text outlined color="error" depressed @click.native="close()"
              >Cancelar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="preview" width="800" style="width: 100%; height: auto;">
      <img :src="url" alt="Nature" style="width: 100%; height: auto;" />
    </v-dialog>

    <!-- Configuracion de Carousel -->
    <v-dialog v-model="dialog" width="400">
      <v-card>
        <v-form ref="formCarousel">
          <v-card-title>
            <span>{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <!-- <v-row wrap>
              <v-col cols="12" class="d-flex"> -->
            <v-text-field
              v-model="editedItem.Nombre"
              label="Nombre"
              :rules="notNull"
              required
              autofocus
            ></v-text-field>
            <!-- </v-col>
              <v-col cols="12" class="d-flex"> -->
            <v-textarea
              v-model="editedItem.Descripcion"
              label="Descripcion"
              rows="2"
              :rules="notNull"
              required
              style="padding: 0px"
            ></v-textarea>
            <!-- </v-col>
              <v-col cols="12"> -->
            <v-select
              clearable
              v-model="editedItem.CanalID"
              :items="canales"
              label="Canal"
              item-value="CanalId"
              item-text="Nombre"
              append-icon="keyboard_arrow_down"
              style="padding: 0px"
            ></v-select>
            <!-- </v-col> -->
            <v-autocomplete
              :items="listGruposComisiones"
              :filter="customFilter"
              v-model="gruposcomisionesSelected"
              label="Grupos Comisiones"
              hint="Dejar vacio para usar todos"
              append-icon="keyboard_arrow_down"
              multiple
              chips
              deletable-chips
              persistent-hint
              clearable
              style="padding: 0px"
            ></v-autocomplete>
            <v-row wrap>
              <v-col cols="12" sm="6" class="d-flex">
                <date-selector
                  label="Fecha Inicio"
                  :fecha.sync="editedItem.StartDate"
                  :rules="notNull"
                ></date-selector>
              </v-col>
              <v-col cols="12" sm="6" class="d-flex">
                <date-selector
                  label="Fecha Fin"
                  :fecha.sync="editedItem.EndDate"
                ></date-selector>
              </v-col>
            </v-row>
            <!-- <v-col cols="12" class="d-flex"> -->
            <v-switch
              color="primary"
              :label="`Estado: ${editedItem.bActivo ? 'Activo' : 'Inactivo'}`"
              v-model="editedItem.bActivo"
            ></v-switch>
            <!-- </v-col>
              <v-col cols="12" class="d-flex"> -->
            <v-file-input
              required="editedIndex < 0"
              :rules="editedIndex < 0?fileRules: undefined"
              label="Seleccionar archivo"
              v-model="file"
              accept="image/*"
              :show-size="1000"
              style="padding: 0px"
              hint="Dejar vacio para usar todos"
            ></v-file-input>
            <span style="color:gray;font-size:12px;padding: 0px">La imagen se adapta al dispositivo.</span>
            <!-- </v-col>
            </v-row> -->
          </v-card-text>
          <v-card-actions>
            <v-btn
              depressed
              color="primary"
              @click.native="save('carousel')"
              :disabled="!formMasivaIsValid"
              >Guardar</v-btn
            >
            <v-btn text depressed color="error" @click.native="close()"
              >Cancelar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>



<script>
import apiEsquema from "@/api/esquemas";
import apiHome from "@/api/home";
import apiAsociados from "@/api/asociados";
import { global } from "@/api/global";
// import moment from 'moment'

export default {
  data: () => ({
    dialog: false,
    reglas: false,
    preview: false,
    listaCategoria: ["Esquema", "Anuncio"],
    file: [],
    url: "",
    search: "",
    headersReglas: [
      { text: "NOMBRE", value: "Nombre" },
      { text: "DESCRIPCION", value: "Descripcion" },
      { text: "CANAL", value: "CanalID" },
      { text: "ESTADO", value: "bActivo" },
      { text: "", value: "action", sortable: false },
    ],
    headersCarousel: [
      { text: "NOMBRE", value: "Nombre" },
      { text: "DESCRIPCION", value: "Descripcion" },
      { text: "CANAL", value: "CanalID" },
      { text: "FECHA INICIO", value: "StartDate" },
      { text: "FECHA FIN", value: "EndDate" },
      { text: "ESTADO", value: "bActivo" },
      { text: "", value: "action", sortable: false },
    ],
    itemsReglas: [],
    itemsCarousel: [],
    canales: [],
    editedIndex: -1,
    editedItem: {
      DocumentoCompartidoID: 0,
      CanalID: 0,
      GrupoComisionIds: "",
      Categoria: "",
      Nombre: "",
      Descripcion: "",
      NombreArchivo: "",
      bActivo: false,
    },
    defaultItem: {
      DocumentoCompartidoID: null,
      CanalID: null,
      GrupoComisionIds: "",
      Categoria: "",
      Nombre: "",
      Descripcion: "",
      NombreArchivo: null,
      bActivo: false,
    },
    menuStartDate: null,
    menuEndDate: null,
    paginationMaster: {
      sortBy: "Nombre",
      descending: false,
      rowsPerPage: 5,
    },
    notNull: [(v) => !!v || "Este campo es requerido"],
    fileRules: [
      (v) =>
        (v && v.size < 4 * 1000 * 1000) ||
        "El archivo que esta intentando subir excede los 4 MB",
    ],
    breadcrumb: [
      {
        text: "Mantenimiento Documento",
        disabled: true,
      },
    ],
    listGruposComisiones: [],
    gruposcomisionesSelected: [],
    customFilter(item, queryText) {
      const hasValue = (val) => (val != null ? val : "");
      const text = hasValue(item.Nombre);
      const query = hasValue(queryText);
      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Documento" : "Editar Documento";
    },
    formMasivaIsValid() {
      return (
        (this.file !== "" && this.file !== null) ||
        (this.editedItem.NombreArchivo !== null &&
          this.editedItem.NombreArchivo !== "")
      );
    },
    resize() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return "";
      } else {
        return "width: 100%";
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();

      if(!val){
        
        this.$refs.formCarousel.reset();
        this.editedIndex = -1;
      }
    },
    "editedItem.CanalID": function () {
      this.refreshGruposComisiones();
    },
    reglas(val){
      if(!val){
        this.$refs.formReglas.reset();
        this.editedIndex = -1;
      }
    }
  },
  created() {
    this.initialize(true);
  },
  methods: {
    initialize(refresh) {
      this.dialog = false;
      this.reglas = false;
      this.editedIndex = -1;
      this.file = [];
      this.editedItem = Object.assign({}, this.defaultItem);

      if (refresh) {
        apiEsquema.getEsquemasList().then((response) => {
          this.itemsReglas = response.data;
        });
        apiEsquema.getAnunciosList().then((response) => {
          this.itemsCarousel = response.data;
        });
      }
    },
    editItem(item, document) {
      this.gruposcomisionesSelected = [];
      if (item.GrupoComisionIds != null && item.GrupoComisionIds != "") {
        this.gruposcomisionesSelected = item.GrupoComisionIds.split(",").map(
          (m) => {
            return { value: parseInt(m) };
          }
        );
      }

      if (document === "reglas") {
        this.editedIndex = this.itemsReglas.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.reglas = true;
      } else {
        this.editedIndex = this.itemsCarousel.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      }
    },
    deleteItem(item, document) {
      if (document === "reglas") {
        // const index = this.itemsReglas.indexOf(item)
        if (confirm("Esta seguro que desea eliminar este registro?")) {
          apiEsquema
            .deleteDocument(item.DocumentoCompartidoID)
            .then(() => {
              this.initialize(true);
            })
            .catch((error) => {
              this.loading = false;
              this.$store.dispatch("setError", true);
              this.$store.dispatch("setErrorMessage", error.message);
            });
        }
      } else {
        // const index = this.itemsCarousel.indexOf(item)
        if (confirm("Esta seguro que desea eliminar este registro?")) {
          apiEsquema
            .deleteDocument(item.DocumentoCompartidoID)
            .then(() => {
              this.initialize(true);
            })
            .catch((error) => {
              this.loading = false;
              this.$store.dispatch("setError", true);
              this.$store.dispatch("setErrorMessage", error.message);
            });
        }
      }
    },
    close() {
      this.reglas = false;
      this.dialog = false;
      this.editedIndex = -1;
      this.$refs.formReglas.reset();
      this.$refs.formCarousel.reset();
    },
    save(document) {
      if (document === "reglas") {
        if (this.$refs.formReglas.validate()) {
          this.loader = "loading";
          let data = new FormData();
          data.append("Id", this.editedItem.DocumentoCompartidoID);
          data.append("Categoria", this.editedItem.Categoria);
          data.append("Nombre", this.editedItem.Nombre);
          data.append("Descripcion", this.editedItem.Descripcion);
          data.append("CanalID", this.editedItem.CanalID);
          data.append("GrupoComisionIds", this.gruposcomisionesSelected);
          data.append("Estado", this.editedItem.bActivo);
          data.append("Archivos", this.file);
          this.loading = true;
          apiEsquema
            .saveEsquema(data)
            .then(() => {
              this.loading = false;
              this.initialize(true);
            })
            .catch((error) => {
              this.loading = false;
              this.$store.dispatch("setError", true);
              this.$store.dispatch("setErrorMessage", error.message);
            });
          this.$refs.formReglas.reset();
          this.close();
        }
      } else {
        if (this.$refs.formCarousel.validate()) {
          this.loader = "loading";
          let data = new FormData();
          data.append("Id", this.editedItem.DocumentoCompartidoID);
          data.append("Nombre", this.editedItem.Nombre);
          data.append("Descripcion", this.editedItem.Descripcion);
          data.append("CanalID", this.editedItem.CanalID);
          data.append("GrupoComisionIds", this.gruposcomisionesSelected);
          data.append("Estado", this.editedItem.bActivo);
          data.append("StartDate", this.editedItem.StartDate);
          data.append("EndDate", this.editedItem.EndDate);
          data.append("Archivos", this.file);
          this.loading = true;
          apiEsquema
            .saveAnuncio(data)
            .then(() => {
              this.loading = false;
              this.initialize(true);
            })
            .catch((error) => {
              this.loading = false;
              this.$store.dispatch("setError", true);
              this.$store.dispatch("setErrorMessage", error.message);
            });
          this.$refs.formCarousel.reset();
          this.close();
        }
      }
    },
    getCanalName(canalID) {
      var canal = this.canales.filter((c) => {
        return c.CanalId === canalID;
      })[0];
      return canal === undefined ? "" : canal.Nombre;
    },
    previewAnuncio(item) {
      this.url =global.baseUrl + item.Path;
      this.preview = true;
    },
     previewRegla(item) {
      this.url =global.baseUrl + item.Path;
      this.preview = true;
    },
    refreshGruposComisiones() {
      if (this.editedItem.CanalID != null) {
        apiAsociados
          .getGruposComisionesByCanalID(this.editedItem.CanalID)
          .then((response) => {
            this.listGruposComisiones = response.data;
          })
          .catch((error) => {
            this.setError(error);
          });
      }
    },
  },
  mounted() {
    // this.$store.dispatch("setBreadcrumbs", this.breadcrumb);
    apiHome.getCanales().then((response) => {
      this.canales = response.data;
    });
  },
};
</script>